









































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { getRoomRequireReadList, editRoomRequireRead, delRoomRequireRead } from '@/api/house-require-read'
export default Vue.extend({
	data() {
		return {
			editRoomRequireRead,
			...useTable({
				name: '订房必读',
				formdata: {
					/** ID */
					id: 0,
					/** 名称 */
					name: '',
					/**标题 */
					title: undefined,
				},
				getData: (query) => getRoomRequireReadList(query).then((res) => res.data),
				delData: (row) => delRoomRequireRead({ id: row.id }),
			}),
		}
	},
	created() {
		this.tableGetData()
	},
})
