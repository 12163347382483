import request, { ResponsePageableData } from '@/libs/request'

/** 房间楼层列表 */
export const getFloorList = (data?) => request<ResponsePageableData>({ url: '/adminapi/Floor/getFloorList', data })

/** 房间楼层新增/编辑 */
export const editFloor = (data?) => request<ResponsePageableData>({ url: '/adminapi/Floor/editFloor', data })

/** 房间楼层删除 */
export const delFloor = (data?) => request<ResponsePageableData>({ url: '/adminapi/Floor/delFloor', data })
