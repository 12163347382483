





































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { getBuildingList, editBuilding, delBuilding } from '@/api/house-building'
export default Vue.extend({
	data() {
		return {
			editBuilding,
			...useTable({
				name: '房间楼号',
				formdata: {
					/** ID */
					id: 0,
					/** 名称 */
					name: '',
				},
				getData: (query) => getBuildingList(query).then((res) => res.data),
				delData: (row) => delBuilding({ id: row.id }),
			}),
		}
	},
	created() {
		this.tableGetData()
	},
})
