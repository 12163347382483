





































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { getFloorList, editFloor, delFloor } from '@/api/house-floor'
export default Vue.extend({
	data() {
		return {
			editFloor,
			...useTable({
				name: '房间楼层',
				formdata: {
					/** ID */
					id: 0,
					/** 名称 */
					name: '',
				},
				getData: (query) => getFloorList(query).then((res) => res.data),
				delData: (row) => delFloor({ id: row.id }),
			}),
		}
	},
	created() {
		this.tableGetData()
	},
})
