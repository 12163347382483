<template>
	<div class="m-4">
		<el-card
			:body-style="{
				'min-height': 'calc(100vh - 90px)',
			}"
		>
			<el-row class="h-full">
				<el-col :span="leftSpan" class="bg-gray-100" style="min-height: calc(100vh - 135px)">
					<div class="p-3">
						<el-input v-model="tableQuery.search_word" placeholder="输入房间号搜索" clearable @clear="getTableData">
							<el-button slot="append" icon="el-icon-search" @click="getTableData"></el-button>
						</el-input>
						<!-- <div class="mt-2">房间状态筛选</div> -->
						<div v-loading="houseStateLoading">
							<el-row :gutter="12">
								<el-col class="text-white mt-2" :span="12" v-for="item in houseStateList" :key="item.id">
									<div
										class="rounded-md px-4 py-3 pr-5 flex justify-between relative cursor-pointer"
										:style="{ background: item.bgcolor }"
										@click="clickHouseState(item)"
									>
										<div>{{ item.name }}</div>
										<div>{{ item.number }}</div>
										<i class="el-icon-s-flag text-white absolute top-0.5 right-0.5" v-if="item.isCheck"></i>
									</div>
								</el-col>
							</el-row>
						</div>
						<div>
							<SelectRoomType class="w-full mt-2" v-model="tableQuery.room_type_id" filterable clearable @change="getTableData()" />
							<SelectHouseBuilding class="w-full mt-2" v-model="tableQuery.building_id" filterable clearable @change="getTableData()" />
							<SelectHouseFloor class="w-full mt-2" v-model="tableQuery.floor_id" filterable clearable @change="getTableData()" />
						</div>
					</div>
				</el-col>
				<el-col :span="rightSpan">
					<div class="p-3">
						<el-radio-group v-model="tableQuery.hotel_id" @change="getTableData()">
							<el-radio style="margin-right: 4px" v-for="item in hotelList" :key="item.id" :label="item.id" border>{{ item.name }}</el-radio>
						</el-radio-group>

						<div v-loading="table_state.loading" class="mt-3">
							<el-row :gutter="10" v-if="houseList.length">
								<el-col v-for="item in houseList" :key="item.id" v-bind="houseSize">
									<div
										class="px-1 py-2 rounded border-dotted border-2"
										:style="{
											background: complateHouseColor(item),
											color: item.state ? '#ffffff' : '',
										}"
									>
										<div class="font-bold text-md">{{ item.number }}房</div>
										<div style="font-size: 14px">{{ item.building.name }}号({{ item.floor.name }})</div>
										<div style="font-size: 14px">{{ item.room_type.name }}</div>
									</div>
								</el-col>
							</el-row>
							<el-empty v-else></el-empty>
						</div>

						<TablePagination
							:query="tableQuery"
							:state="table_state"
							:pageSize="[20, 40, 60, 80]"
							@current-change="getTableData()"
							@size-change=";(tableQuery.index = 1), getTableData()"
						/>
					</div>
				</el-col>
			</el-row>
		</el-card>
	</div>
</template>
<script>
import { getHouseState, getHouseList } from '@/api/house'
import { getHotelList } from '@/api/hotel'

import SelectHotel from '@/components/select/select-hotel.vue' //酒店选择器
import SelectRoomType from '@/components/select/select-room-type.vue' //户型选择器
import SelectHouseBuilding from '@/components/select/select-house-building.vue' //楼号选择器
import SelectHouseFloor from '@/components/select/select-house_floor.vue' //楼层选择器
export default {
	components: {
		SelectHotel,
		SelectRoomType,
		SelectHouseBuilding,
		SelectHouseFloor,
	},
	data() {
		return {
			leftSpan: 6,
			rightSpan: 18,
			tableQuery: {
				search_word: undefined,
				hotel_id: undefined, //酒店筛选
				state: undefined, //房间状态筛选
				index: 1,
				limit: 20,
			},
			table_state: {
				total: 0,
				loading: false,
			},
			houseStateLoading: false,
			houseStateList: [], //房态列表
			hotelList: [], //酒店列表
			houseList: [], //房价列表
			houseSize: {
				xs: 12,
				sm: 6,
				md: 4,
				lg: 3,
				xl: 2,
			},
		}
	},
	methods: {
		//房间状态
		getHouseState() {
			this.houseStateLoading = true
			getHouseState({ hotel_id: this.tableQuery.hotel_id })
				.then((res) => {
					const colorArr = ['#34D399', '#F472B6', '#FBBF24', '#60A5FA', '#6B7280']
					this.houseStateList = res.data
					this.houseStateList.forEach((item, index) => {
						this.$set(item, 'bgcolor', colorArr[index])
						this.$set(item, 'isCheck', false)
					})
				})
				.finally(() => {
					this.houseStateLoading = false
				})
		},
		complateHouseColor(item) {
			const nodeItem = this.houseStateList.find((it) => it.id == item.state) || {}
			return nodeItem.bgcolor || ''
		},
		clickHouseState(item) {
			this.houseStateList.forEach((it) => {
				it.isCheck = false
			})
			if (item.id == this.tableQuery.state) {
				this.tableQuery.state = undefined
				return this.getTableData()
			}
			this.tableQuery.state = item.id
			item.isCheck = true
			this.getTableData()
		},
		getHotelList() {
			return new Promise((resolve, reject) => {
				getHotelList()
					.then((res) => {
						this.hotelList = res.data.rows || []
						// @ts-ignore
						this.tableQuery.hotel_id = this.hotelList[0] && this.hotelList[0].id
						return resolve()
					})
					.catch(() => {
						return reject()
					})
			})
		},
		getTableData() {
			this.table_state.loading = true
			getHouseList(this.tableQuery)
				.then((res) => {
					this.table_state.total = res.data.total
					this.houseList = res.data.rows
				})
				.finally(() => {
					this.table_state.loading = false
				})
		},
	},
	mounted() {
		this.getHotelList().then((res) => {
			this.getHouseState()
			this.getTableData()
		})
	},
}
</script>
