import request, { ResponsePageableData } from '@/libs/request'

/** 户型列表 */
export const getRoomList = (data?) => request<ResponsePageableData>({ url: '/adminapi/RoomType/getRoomTypeList', data })

/** 创建户型数据结构 */
export const createRoomRow = () => ({
	id: 0,
	/** 名称 */
	name: '',
	/** 地址 */
	address: '',
	/** 封面图 */
	cover_image: '',
	/** 详情图 */
	detail_images: [] as (number | string)[],
	/** 标签（json） */
	label_ids: [] as (number | string)[],
	/** 原价（单位分） */
	original_price: 0,
	/** 价格（单位分） */
	price: 0,
	/** 设施（json） */
	facility_ids: [] as (number | string)[],
	/** 政策（json） */
	policy_ids: [] as (number | string)[],
	/**选择【】小时入住 */
	choose_hours: 0,
})

/** 户型新增/编辑 */
export const editRoom = (data) => request({ url: '/adminapi/RoomType/editRoomType', data })

/** 户型删除 */
export const delRoom = (data?) => request({ url: '/adminapi/RoomType/delRoomType', data })
