



























































import Vue from 'vue'
import SelectHotel from '@/components/select/select-hotel.vue' //酒店选择器
import SelectRoomType from '@/components/select/select-room-type.vue' //户型选择器
import SelectHouseBuilding from '@/components/select/select-house-building.vue' //楼号选择器
import SelectHouseFloor from '@/components/select/select-house_floor.vue' //楼层选择器
import SelectHOuseState from '@/components/select/select-house-state.vue' //房态选择器
import { useTable } from '@/components/table/table-hook'
import { getHouseList, editHouse, delHouse, createHouseRow, setHouseState } from '@/api/house'
import { getTreeName } from '@/libs/vue-tools/index'
export default Vue.extend({
	components: {
		SelectHotel,
		SelectRoomType,
		SelectHouseBuilding,
		SelectHouseFloor,
		SelectHOuseState,
	},
	data() {
		return {
			editHouse,
			...useTable({
				name: '房间',
				formdata: createHouseRow(),
				query: {
					/** 筛选城市ID */
					city_id: 0,
				},
				getData: (query) =>
					getHouseList(query).then((res) => {
						// 这里演示对拿到的数据追加一些自定义属性
						res.data.rows.forEach((it) => {
							it._custom_name = '自定义的属性'
						})
						return res.data
					}),
				delData: (row) => delHouse({ id: row.id }),
			}),
			//设置房态弹窗数据
			houseStateFormData: {
				state: undefined,
			},
		}
	},

	methods: {
		getTreeName,
		onSubmit(row) {
			let param = row
			param.label_ids = JSON.stringify(param.label_ids) //标签
			param.facility_ids = JSON.stringify(param.facility_ids) //设施
			param.policy_ids = JSON.stringify(param.policy_ids) //政策
			return editHouse(param).then(() => this.$message.success('保存成功') && this.tableGetData())
		},
		edit(row) {
			;(this.$refs.formDialogRef as any).open(row)
		},
		//设置房态
		setting(row) {
			;(this.$refs.HouseStateRef as any).open({ id: row.id, state: row.state })
		},
		sumbitHouseState(row) {
			return setHouseState(row).then(() => this.$message.success('保存成功') && this.tableGetData())
		},
	},
	created() {
		this.tableGetData()
	},
})
